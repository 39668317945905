<template>
    <div class="row">
      <ModalEditarHogar ref="ModalHogares" @refrescar="obtener_cola_huella()"></ModalEditarHogar>
      <ModalEditarTrazados ref="ModalTrazados" @refrescar="obtenerYRefrescarColas()" :lista_olt="listaOLT"></ModalEditarTrazados>
      <!-- BUSCADOR DE DOMICILIOS -->
      <div class="col-12">
          <div class="card page-card border-0 shadow-lg my-4">
            <!-- Header -->
            <div class="d-flex align-items-baseline mx-4 my-4">
              <h1 >Buscador de domicilios</h1>
              <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage"></i>
            </div>
            <div class="card-body">
   
              <!-- Error cargando -->
              <div v-if="mensajedeError" class="alert alert-warning d-flex align-items-center" role="alert">
                <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
                <div class="d-flex flex-column text-start">
                  <div class="text-danger">
                    {{mensajedeError}}
                  </div>  
  
                </div>
              </div>
              <!-- ################## -->
              <!-- FILTROS DOMICILIOS -->
              <!-- ################## -->
              <div>
                <form class="user d-flex flex-column" @submit.prevent novalidate>
                  <div class="row">

                      <div class="row">
                        <!-- ID_DOMICILIO -->
                        <div class="col-sm-12 col-xxl-6 my-1">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="ID_DOMICILIO" class="form-control-label mx-1  d-flex align-items-center">ID_DOMICILIO</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">
                                      <input id="ID_DOMICILIO"  class="form-control w-100 m-0" type="text" :disabled="cargandoDomicilios" maxlength="38" v-model="$v.ID_DOMICILIO.$model" placeholder="ID DOMICILIO" :class="{ 'is-invalid': $v.ID_DOMICILIO.$error}" autofocus autocomplete="off">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <div class="row justify-content-end text-start">
                              <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                <small v-show="$v.ID_DOMICILIO.$dirty && !$v.ID_DOMICILIO.maxLength" class="text-danger">
                                  No puede introducir tantos caracteres
                                </small>                                
                              </div>
                            </div>

                        </div>

                        <!-- CODIGO_OLT -->
                        <div class="col-sm-12 col-xxl-6 my-1">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="CODIGO_OLT" class="form-control-label mx-1  d-flex align-items-center">CODIGO_OLT</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">
                                      <input id="CODIGO_OLT" class="form-control w-100 m-0" list="listaOLTHogares" :disabled="cargandoDomicilios"  maxlength="23" v-model.lazy="$v.CODIGO_OLT.$model" placeholder="CODIGO OLT" :class="{ 'is-invalid': $v.CODIGO_OLT.$error}" autocomplete="off" @change="loseFocus">
                                      <datalist id="listaOLTHogares">
                                        <option v-for="(item, index) in listaOLT" :key="index" :value="item.CODIGO_OLT">{{item.POBLACION}}</option> 
                                      </datalist> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <div class="row justify-content-end text-start">
                              <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                <small v-show="$v.CODIGO_OLT.$dirty && !$v.CODIGO_OLT.maxLength" class="text-danger">
                                  No puede introducir tantos caracteres
                                </small>                                
                              </div>
                            </div>
                        </div>

                        <!-- CODIGO_CTO -->
                        <div class="col-sm-12 col-xxl-6 my-1">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="CODIGO_CTO" class="form-control-label mx-1  d-flex align-items-center">CODIGO_CTO</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">
                                      <input id="CODIGO_CTO" class="form-control w-100 m-0" type="text" :disabled="cargandoDomicilios" maxlength="14" v-model="$v.CODIGO_CTO.$model" placeholder="CODIGO CTO" :class="{ 'is-invalid': $v.CODIGO_CTO.$error}" autocomplete="off">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            <div class="row justify-content-end text-start">
                              <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                <small v-show="$v.CODIGO_CTO.$dirty && !$v.CODIGO_CTO.maxLength" class="text-danger">
                                  No puede introducir tantos caracteres
                                </small>                                
                              </div>
                            </div>
                        </div>

                        <!-- Provincia -->
                        <div class="col-sm-12 col-xxl-6 my-1">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="Provincia" class="form-control-label mx-1  d-flex align-items-center">Provincia</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">  
                                      <input id="Provincia" class="form-control w-100 m-0" list="listaProvincias" :disabled="cargandoDomicilios" maxlength="65" v-model.lazy="$v.provincia.$model" placeholder="Provincia" :class="{ 'is-invalid': $v.provincia.$error}" autocomplete="off" @change="loseFocus;">
                                      <datalist id="listaProvincias">
                                        <option v-for="(item, index) in provincias" :key="index" :value="item.PROVINCIA"></option> 
                                      </datalist>
                                    </div>
                                  </div>                      
                                </div>
                              </div>
                              <div class="row justify-content-end text-start">
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                  <small v-show="$v.provincia.$dirty && !$v.provincia.maxLength" class="text-danger">
                                    No puede introducir tantos caracteres
                                  </small>                                
                                </div>
                            </div>                                  
                        </div>

                        <!-- Pueblo -->
                        <div class="col-sm-12 col-xxl-6 my-1">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="poblacion" class="form-control-label mx-1  d-flex align-items-center">Población</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">  
                                      <input id="poblacion" class="form-control w-100 m-0" list="listaPueblosInput" :disabled="cargandoDomicilios" maxlength="65" v-model.lazy="poblacion" placeholder="Población" :class="{ 'is-invalid': $v.poblacion.$error}" autocomplete="off" @change="loseFocus; cambioPoblacion($event)">
                                      <datalist id="listaPueblosInput">
                                        <option v-for="(item, index) in pueblos" :key="index" :data-provincia="item.provincia" :value="item.pueblo">{{item.provincia}}</option> 
                                      </datalist>     
                                    </div>
                                  </div>                            
                                </div>
                              </div>
                              <div class="row justify-content-end text-start">
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                  <small v-show="$v.poblacion.$dirty && !$v.poblacion.maxLength" class="text-danger">
                                    No puede introducir tantos caracteres
                                  </small>                                
                                </div>
                            </div>    
                        </div>    
                        <!-- Calle -->
                        <div class="col-sm-12 col-xxl-6 my-1">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="calle" class="form-control-label mx-1  d-flex align-items-center">Nombre vía</label>
                                </div>
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                                  <div class="row d-flex flex-row">
                                    <div class="col-10">  
                                      <div class="input-group">
                                      <input id="calle" class="form-control  m-0" maxlength="128" :disabled="cargandoDomicilios" v-model="$v.calle.$model" placeholder="Ej.: San Martín" autocomplete="off">                                
                                      </div>
                                    </div>
                                    <div class="col-2 px-0 text-start align-self-center">
                                      <i class="fa-solid fa-circle-question clickable " @click="infoDomicilio"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                        </div>  
                        <!-- Numero -->
                        <div class="col-sm-12 col-xxl-6 my-1">
                              <div class="row d-flex align-items-center">
                                <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                  <label for="numero" class="form-control-label mx-1  d-flex align-items-center">Número</label>
                                </div>
                                <div class="number-input">
                                  <input id="numero" class="form-control w-100 m-0" type="text" :disabled="cargandoDomicilios" min="0"  maxlength="65" placeholder="Número" v-model="numero" autocomplete="off">                                                   
                                </div>
                              </div>
                        </div>   
                      </div>

                      <hr class="mt-4">
                      <div class="d-flex justify-content-center">
                          <button type="button" class="btn btn-secondary boton-generar mx-2" @click="resetearFormulario()" title="Limpiar filtros altas">        
                            Limpiar
                          </button>
                          <button type="button" :disabled="(!ID_DOMICILIO && !CODIGO_OLT && !CODIGO_CTO && !provincia && !poblacion && !calle && !numero) || cargandoDomicilios" class="btn btn-primary boton-generar mx-2" @click="getDomicilios()">
                                <i  v-if="!cargandoDomicilios" class="fa-solid fa-magnifying-glass mx-1"></i>
                                <div v-else class="spinner-border spinner-border-sm" role="status">
                                  <span class="visually-hidden">Cargando domicilios...</span>
                                </div>
                                Buscar domicilios
                          </button>
                      </div>	

                  </div>
                </form>          
              </div>
              <!-- ########## -->
              <!-- DOMICILIOS -->
              <!-- ########## -->
              <b-overlay :show="cargandoDomicilios || cargandoConfirmarCambiosHogares">
                <!-- Tabla -->
                <div  class="mt-4 p-4 shadow">

                  <!-- Opciones en masa -->
                  <div class="d-flex flex-column align-items-center flex-md-row align-items-md-baseline justify-content-start mb-2 noselect">
                    <div class="mx-1" @click="seleccionarHogares($event)">
                      <input type="checkbox" name="selectAll" value="all" id="selectAllHogares">        
                      <label for="selectAllHogares" class="form-control-label mx-1 clickable">Seleccionar todo</label>
                    </div>
                    <div class="d-flex ">
                      <div class="mx-1" @click="editarHogaresSeleccionados()">
                        <i type="button" class="fa-solid fa-pencil mx-1 icon-btn text-primary editar-btn" title="Editar" id="editarHogaresSeleccionados"></i>
                        <label for="editarHogaresSeleccionados" class="form-control-label mx-1 clickable">Editar</label>
                      </div>
                      <div class="mx-1" @click="borrarHogaresSeleccionados()">
                        <i type="button" class="fa-solid fa-trash-alt mx-1 icon-btn text-danger borrar-btn" title="Borrar" id="borrarHogaresSeleccionados"></i>
                        <label for="borrarHogaresSeleccionados" class="form-control-label mx-1 clickable">Borrar</label>
                      </div>
                    </div>
                  </div>

                    <table id="tablaDomicilios" class="table table-striped w-100 table-tiny" width="100%">
                        <thead>
                          <tr class="noselect">
                            <th scope="col" style="min-width: 1rem; max-width: 1rem;"></th>
                            <th scope="col" class="align-middle" style="min-width: 3.5rem;">ACCION</th>
                            <th scope="col" class="align-middle" style="min-width: 10rem;">DIRECCION COMPLETA</th>
                            <th scope="col" class="align-middle">ID_DOMICILIO</th>
                            <th scope="col" class="align-middle">HOGAR_POTENCIAL</th>
                            <th scope="col" class="align-middle">CODIGO_POSTAL</th>
                            <th scope="col" class="align-middle">PROVINCIA</th>
                            <th scope="col" class="align-middle">POBLACION</th>
                            <th scope="col" class="align-middle">TIPO_VIA</th>
                            <th scope="col" class="align-middle">NOMBRE_VIA</th>
                            <th scope="col" class="align-middle">ID_TECNICO</th>
                            <th scope="col" class="align-middle">NUMERO</th>
                            <th scope="col" class="align-middle">BIS</th>
                            <th scope="col" class="align-middle">BLOQUE_FINCA</th>
                            <th scope="col" class="align-middle">IDENTIFICADOR_FINCA</th>
                            <th scope="col" class="align-middle">LETRA_FINCA</th>
                            <th scope="col" class="align-middle">ESCALERA</th>
                            <th scope="col" class="align-middle">PLANTA</th>
                            <th scope="col" class="align-middle">MANO_1</th>
                            <th scope="col" class="align-middle">MANO_2</th>
                            <th scope="col" class="align-middle">OBSERVACIONES</th>
                            <th scope="col" class="align-middle">FLAG_DUMMY</th>
                            <th scope="col" class="align-middle">CODIGO_INE</th>
                            <th scope="col" class="align-middle">CODIGO_CENSAL</th>
                            <th scope="col" class="align-middle">CODIGO_PAI</th>
                            <th scope="col" class="align-middle" style="min-width: 6rem;">CODIGO_OLT</th>
                            <th scope="col" class="align-middle">CODIGO_CTO</th>
                            <th scope="col" class="align-middle">TIPO_CTO</th>
                            <th scope="col" class="align-middle">DIRECCION_CTO</th>
                            <th scope="col" class="align-middle">TIPO_PERMISO</th>
                            <th scope="col" class="align-middle">TIPO_CAJA_DERIVACION</th>
                            <th scope="col" class="align-middle">NUMERO_UI</th>
                            <th scope="col" class="align-middle">NUMERO_VIVIENDAS</th>
                            <th scope="col" class="align-middle">FECHA_ALTA</th>
                            <th scope="col" class="align-middle">CODIGO_CAJA_DERIVACION</th>
                            <th scope="col" class="align-middle">UBICACION_CAJA_DERIVACION</th>
                            <th scope="col" class="align-middle" style="min-width: 13rem;">GESCAL_37</th>
                          </tr>
                        </thead>
                        <tbody class="align-middle text-start">                   
                        </tbody>
                    </table>
                </div>
               
              </b-overlay>
              <!-- ############### -->
              <!-- COLA DOMICILIOS -->
              <!-- ############### --> 
              <div v-show="cola_edicion_huella && cola_edicion_huella.length" class="mt-4 p-4 shadow">
                 <!-- Opciones en masa -->
                <div v-if="!cargandoConfirmarCambiosHogares" class="d-flex flex-column align-items-center flex-md-row align-items-md-baseline justify-content-start mb-2 noselect">
                  <div class="mx-1" @click="eliminarTodaColaHogares()">
                      <i type="button" class="fa-solid fa-xmark mx-1 icon-btn text-danger" title="Editar" id="descartarCambiosTrazados"></i>
                      <label for="descartarCambiosTrazados" class="form-control-label mx-1 clickable">Descartar todo</label>
                  </div>
                </div>
                <!-- Lista cambios hogares -->
                  <!-- Action card header -->
                  <div class="d-flex align-items-baseline justify-content-start">
                    <h4>Cola de cambios de domicilios</h4>
                    <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoCambiosHogares()"></i>
                  </div>
                  <b-overlay :show="cargandoConfirmarCambiosHogares">
                    <table id="cambiosHogaresTabla" class="table table-striped w-100 table-tiny" width="100%">
                        <thead>
                          <tr class="noselect">
                            <th scope="col" class="align-middle">ACCION</th>
                            <th scope="col" class="align-middle" style="min-width: 10rem;">DIRECCION COMPLETA</th>
                            <th scope="col" class="align-middle">ID_DOMICILIO</th>
                            <th scope="col" class="align-middle">HOGAR_POTENCIAL</th>
                            <th scope="col" class="align-middle">CODIGO_POSTAL</th>
                            <th scope="col" class="align-middle">PROVINCIA</th>
                            <th scope="col" class="align-middle">POBLACION</th>
                            <th scope="col" class="align-middle">TIPO_VIA</th>
                            <th scope="col" class="align-middle">NOMBRE_VIA</th>
                            <th scope="col" class="align-middle">ID_TECNICO</th>
                            <th scope="col" class="align-middle">NUMERO</th>
                            <th scope="col" class="align-middle">BIS</th>
                            <th scope="col" class="align-middle">BLOQUE_FINCA</th>
                            <th scope="col" class="align-middle">IDENTIFICADOR_FINCA</th>
                            <th scope="col" class="align-middle">LETRA_FINCA</th>
                            <th scope="col" class="align-middle">ESCALERA</th>
                            <th scope="col" class="align-middle">PLANTA</th>
                            <th scope="col" class="align-middle">MANO_1</th>
                            <th scope="col" class="align-middle">MANO_2</th>
                            <th scope="col" class="align-middle">OBSERVACIONES</th>
                            <th scope="col" class="align-middle">FLAG_DUMMY</th>
                            <th scope="col" class="align-middle">CODIGO_INE</th>
                            <th scope="col" class="align-middle">CODIGO_CENSAL</th>
                            <th scope="col" class="align-middle">CODIGO_PAI</th>
                            <th scope="col" class="align-middle" style="min-width: 6rem;">CODIGO_OLT</th>
                            <th scope="col" class="align-middle">CODIGO_CTO</th>
                            <th scope="col" class="align-middle">TIPO_CTO</th>
                            <th scope="col" class="align-middle">DIRECCION_CTO</th>
                            <th scope="col" class="align-middle">TIPO_PERMISO</th>
                            <th scope="col" class="align-middle">TIPO_CAJA_DERIVACION</th>
                            <th scope="col" class="align-middle">NUMERO_UI</th>
                            <th scope="col" class="align-middle">NUMERO_VIVIENDAS</th>
                            <th scope="col" class="align-middle">FECHA_ALTA</th>
                            <th scope="col" class="align-middle">CODIGO_CAJA_DERIVACION</th>
                            <th scope="col" class="align-middle">UBICACION_CAJA_DERIVACION</th>
                          </tr>
                        </thead>
                        <tbody class="align-middle">                   
                        </tbody>
                    </table>
                  </b-overlay>

                    <hr class="mt-4">
                    <div class="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                        <div class="row d-flex align-items-center input-desc">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="DESCRIPCIONHOGARES" class="form-control-label mx-1  d-flex align-items-center noselect">Descripción cambios</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <input id="DESCRIPCIONHOGARES"  class="form-control w-100 m-0" type="text" :disabled="cargandoConfirmarCambiosHogares" maxlength="255" v-model="$v.cambiosHogares.descHogares.$model" placeholder="Descripción cambios huella" :class="{ 'is-invalid': cambiosHogares.dirty && $v.cambiosHogares.descHogares.$invalid}" autocomplete="off">
                              </div>
                            </div>
                            <div class="row justify-content-start">
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                  <small v-show="cambiosHogares.dirty && !$v.cambiosHogares.descHogares.maxLength" class="text-danger">
                                    No puede introducir tantos caracteres
                                  </small>          
                                  <small v-show="cambiosHogares.dirty && !$v.cambiosHogares.descHogares.required" class="text-danger">
                                    Debe de introducir una descripción de los cambios realizados
                                  </small>                         
                                </div>
                            </div>    
                          </div>
                        </div>
                        <small v-if="cargandoConfirmarCambiosHogares" class="text-danger">
                          El proceso podría tardar varios minutos dependiendo de los cambios realizados
                        </small>     
                        <button type="button" class="btn btn-warning fit-content line align-self-center mt-3 m-lg-0" :disabled="cargandoConfirmarCambiosHogares" @click="confirmarCambiosHogares()">
                              <i  v-if="!cargandoConfirmarCambiosHogares" class="fa-solid fa-floppy-disk mx-1"></i>
                              <div v-else class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Cargando...</span>
                              </div>
                              Confirmar cambios
                        </button>
                    </div>	
              </div>

            </div>

            
          </div>

      </div>

      <!-- BUSCADOR DE TRAZADOS -->
      <div class="col-12">
        <div class="card page-card border-0 shadow-lg mb-1">
          
          <!-- Header -->
          <div class="d-flex align-items-baseline mx-4 my-4">
            <h1 >Buscador de trazados</h1>
            <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoTrazados"></i>
          </div>

          <div class="card-body">
            <!-- Error cargando -->
            <div v-if="trazados.mensajedeErrorTrazados" class="alert alert-warning d-flex align-items-center" role="alert">
              <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
              <div class="d-flex flex-column text-start">
                <div class="text-danger">
                  {{trazados.mensajedeErrorTrazados}}
                </div>  

              </div>
            </div>

            <!-- ################ -->
            <!-- FILTROS TRAZADOS -->
            <!-- ################ -->
            <div>
              <form class="user d-flex flex-column" @submit.prevent novalidate>
                <div class="row">

                    <div class="row">

                      <!-- CODIGO_OLT -->
                      <div class="col-sm-12 col-xxl-6 my-1">
                            <div class="row d-flex align-items-center">
                              <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                <label for="CODIGO_OLT_TRAZADOS" class="form-control-label mx-1  d-flex align-items-center">CODIGO_OLT</label>
                              </div>
                              <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                <div class="row d-flex flex-row">
                                  <div class="col-10">
                                    <input id="CODIGO_OLT_TRAZADOS" class="form-control w-100 m-0" list="listaOLTTRAZADOS" :disabled="trazados.cargandoTrazados" maxlength="23" v-model.lazy="$v.trazados.CODIGO_OLT.$model" placeholder="CODIGO OLT" :class="{ 'is-invalid': $v.trazados.CODIGO_OLT.$error}" autocomplete="off" @change="loseFocus">
                                      <datalist id="listaOLTTRAZADOS">
                                        <option v-for="(item, index) in listaOLT" :key="index" :value="item.CODIGO_OLT">{{item.POBLACION}}</option> 
                                      </datalist> 
                                  </div>
                                </div>
                              </div>
                            </div>
                          <div class="row justify-content-end text-start">
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                              <small v-show="$v.trazados.CODIGO_OLT.$dirty && !$v.trazados.CODIGO_OLT.maxLength" class="text-danger">
                                No puede introducir tantos caracteres
                              </small>                                
                            </div>
                          </div>
                      </div>
                      <!-- CODIGO_CTO -->
                      <div class="col-sm-12 col-xxl-6 my-1">
                            <div class="row d-flex align-items-center">
                              <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                                <label for="CODIGO_CTO_TRAZADOS" class="form-control-label mx-1  d-flex align-items-center">CODIGO_CTO</label>
                              </div>
                              <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                                <div class="row d-flex flex-row">
                                  <div class="col-10">
                                    <input id="CODIGO_CTO_TRAZADOS" class="form-control w-100 m-0" type="text" :disabled="trazados.cargandoTrazados"  maxlength="14" v-model="$v.trazados.CODIGO_CTO.$model" placeholder="CODIGO CTO" :class="{ 'is-invalid': $v.trazados.CODIGO_CTO.$error}" autocomplete="off">
                                  </div>
                                </div>
                              </div>
                            </div>
                          <div class="row justify-content-end text-start">
                            <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                              <small v-show="$v.trazados.CODIGO_CTO.$dirty && !$v.trazados.CODIGO_CTO.maxLength" class="text-danger">
                                No puede introducir tantos caracteres
                              </small>                                
                            </div>
                          </div>
                      </div> 
                    </div>

                    <hr class="mt-4">
                    <div class="d-flex justify-content-center" title="Generar reporte">
                        <button type="button" class="btn btn-secondary boton-generar mx-2" @click="resetearFormularioTrazados()" title="Limpiar filtros trazados">        
                          Limpiar
                        </button>
                        <button type="button" :disabled="(!trazados.CODIGO_OLT && !trazados.CODIGO_CTO) || trazados.cargandoTrazados" class="btn btn-primary boton-generar mx-2" @click="getTrazados()">
                              <i  v-if="!trazados.cargandoTrazados" class="fa-solid fa-magnifying-glass mx-1"></i>
                              <div v-else class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Cargando trazados...</span>
                              </div>
                              Buscar trazados
                        </button>
                    </div>	

                </div>
              </form>          
            </div>

            <!-- ######## -->
            <!-- TRAZADOS -->
            <!-- ######## -->
            <b-overlay :show="trazados.cargandoTrazados || cargandoConfirmarCambiosTrazados">
              <!-- Tabla -->
              <div  class="mt-4 p-4 shadow">
                  
                  <!-- Opciones en masa -->
                  <div class="d-flex flex-column align-items-center flex-md-row align-items-md-baseline justify-content-start mb-2 noselect">
                    <div class="mx-1" @click="seleccionarTrazados($event)">
                      <input type="checkbox" name="selectAll" value="all" id="selectAllTrazados">        
                      <label for="selectAllTrazados" class="form-control-label mx-1 clickable">Seleccionar todo</label>
                    </div>
                    <div class="d-flex ">
                      <div class="mx-1" @click="editarTrazadosSeleccionados()">
                        <i type="button" class="fa-solid fa-pencil mx-1 icon-btn text-primary editar-btn" title="Editar" id="editarTrazadosSeleccionados"></i>
                        <label for="editarTrazadosSeleccionados" class="form-control-label mx-1 clickable">Editar</label>
                      </div>
                      <div class="mx-1" @click="borrarTrazadosSeleccionados()">
                        <i type="button" class="fa-solid fa-trash-alt mx-1 icon-btn text-danger borrar-btn" title="Borrar" id="borrarTrazadosSeleccionados"></i>
                        <label for="borrarTrazadosSeleccionados" class="form-control-label mx-1 clickable">Borrar</label>
                      </div>
                    </div>
                  </div>
                 

                  <table id="tablaTrazados" class="table table-striped w-100 table-tiny" width="100%">
                      <thead>
                        <tr class="noselect">
                          <th scope="col" style="min-width: 1rem; max-width: 1rem;"></th>
                          <th scope="col" class="align-middle" style="min-width: 3.5rem;">ACCION</th>
                          <th scope="col" class="align-middle" style="min-width: 6rem;">CODIGO_OLT</th>
                          <th scope="col" class="align-middle">MODELO_OLT</th>
                          <th scope="col" class="align-middle">FRAME_OLT</th>
                          <th scope="col" class="align-middle">TARJETA_OLT</th>
                          <th scope="col" class="align-middle">PUERTO_GPON</th>
                          <th scope="col" class="align-middle">CAPACIDAD_GPON</th>
                          <th scope="col" class="align-middle">CODIGO_CTO</th>
                          <th scope="col" class="align-middle">TIPO_CTO</th>
                          <th scope="col" class="align-middle">DIRECCION_CTO</th>
                          <th scope="col" class="align-middle">SPLITTER_ID</th>
                          <th scope="col" class="align-middle">TIPO_SPLITTER</th>
                          <th scope="col" class="align-middle">PUERTO_CTO</th>
                          <th scope="col" class="align-middle">GESTOR_VERTICAL</th>
                          <th scope="col" class="align-middle">MUTUALIZADO</th>
                        </tr>
                      </thead>
                      <tbody class="align-middle text-start">                   
                      </tbody>
                  </table>
              </div>
            </b-overlay>

            <!-- ############# -->
            <!-- COLA TRAZADOS -->
            <!-- ############# -->
            <div v-show="colaCambiosTrazados && colaCambiosTrazados.length" class="mt-4 p-4 shadow">
              <!-- Opciones en masa -->
              <div v-if="!cargandoConfirmarCambiosTrazados" class="d-flex flex-column align-items-center flex-md-row align-items-md-baseline justify-content-start mb-2 noselect">
                <div class="mx-1" @click="borrarListaCambiosTrazados()">
                    <i type="button" class="fa-solid fa-xmark mx-1 icon-btn text-danger" title="Editar" id="borrarCambiosTrazados"></i>
                    <label for="borrarCambiosTrazados" class="form-control-label mx-1 clickable">Descartar todo</label>
                </div>
              </div>
              <!-- Lista cambios trazados -->
                <!-- Action card header -->
                <div class="d-flex align-items-baseline justify-content-start">
                  <h4>Cola de cambios de trazados</h4>
                  <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoCambiosTrazados()"></i>
                </div>
                <b-overlay :show="cargandoConfirmarCambiosTrazados">
                  <table id="cambiosTrazadosTabla" class="table table-striped w-100 table-tiny" width="100%">
                      <thead>
                         <tr class="noselect">
                          <th scope="col" class="align-middle">ACCION</th>
                          <th scope="col" class="align-middle" style="min-width: 6rem;">CODIGO_OLT</th>
                          <th scope="col" class="align-middle">MODELO_OLT</th>
                          <th scope="col" class="align-middle">FRAME_OLT</th>
                          <th scope="col" class="align-middle">TARJETA_OLT</th>
                          <th scope="col" class="align-middle">PUERTO_GPON</th>
                          <th scope="col" class="align-middle">CAPACIDAD_GPON</th>
                          <th scope="col" class="align-middle">CODIGO_CTO</th>
                          <th scope="col" class="align-middle">TIPO_CTO</th>
                          <th scope="col" class="align-middle">DIRECCION_CTO</th>
                          <th scope="col" class="align-middle">SPLITTER_ID</th>
                          <th scope="col" class="align-middle">TIPO_SPLITTER</th>
                          <th scope="col" class="align-middle">PUERTO_CTO</th>
                          <th scope="col" class="align-middle">GESTOR_VERTICAL</th>
                          <th scope="col" class="align-middle">MUTUALIZADO</th>
                        </tr>
                      </thead>
                      <tbody class="align-middle">                   
                      </tbody>
                  </table>
                </b-overlay>



                <hr class="mt-4">
                  <div class="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                        <div class="row d-flex align-items-center input-desc">
                          <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                            <label for="DESCRIPCIONTRAZADOS" class="form-control-label mx-1  d-flex align-items-center noselect">Descripción cambios</label>
                          </div>
                          <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                            <div class="row d-flex flex-row">
                              <div class="col-10">
                                <input id="DESCRIPCIONTRAZADOS"  class="form-control w-100 m-0" type="text" :disabled="cargandoConfirmarCambiosTrazados" maxlength="255" v-model="$v.cambiosTrazados.descTrazados.$model" placeholder="Descripción cambios trazados" :class="{ 'is-invalid': cambiosTrazados.dirty && $v.cambiosTrazados.descTrazados.$invalid}" autocomplete="off">
                              </div>
                            </div>
                            <div class="row justify-content-start">
                                <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                                  <small v-show="cambiosTrazados.dirty && !$v.cambiosTrazados.descTrazados.maxLength" class="text-danger">
                                    No puede introducir tantos caracteres
                                  </small>          
                                  <small v-show="cambiosTrazados.dirty && !$v.cambiosTrazados.descTrazados.required" class="text-danger">
                                    Debe de introducir una descripción de los cambios realizados
                                  </small>                         
                                </div>
                            </div>    
                          </div>
                        </div>  
                        <small v-if="cargandoConfirmarCambiosTrazados" class="text-danger">
                          El proceso podría tardar varios minutos dependiendo de los cambios realizados
                        </small>     
                        <button type="button" class="btn btn-warning fit-content line boton-generar mt-3 m-lg-0" :disabled="cargandoConfirmarCambiosTrazados" @click="confirmarCambiosTrazados()">
                            <i  v-if="!cargandoConfirmarCambiosTrazados" class="fa-solid fa-floppy-disk mx-1"></i>
                            <div v-else class="spinner-border spinner-border-sm" role="status">
                              <span class="visually-hidden">Cargando...</span>
                            </div>
                            Confirmar cambios
                        </button>
                  </div>	
                  
            </div>




          </div>

          
        </div>

      </div>
    </div>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';
import { confirmAlert, errorAlert, infoAlert, serverErrorAlert, successAlert, warningAlert } from '../../helpers/fastAlert';
import axios from "axios";
import { montarNombreCalle } from '../../helpers/utils';
import 'datatables.net-bs5';
import 'datatables.net-responsive-bs5';
import $ from 'jquery';
import Swal from "sweetalert2";
import { borrarHogarEditor, borrarTrazadoEditor, editarHogarEditor, editarTrazadoEditor } from '../../helpers/EditorInfluencia.js';
import 'datatables.net-select'
import ModalEditarHogar from "./ModalEditarHogar.vue"
import ModalEditarTrazados from "./ModalEditarTrazados.vue"
import { mapState, mapActions } from 'vuex';

export default {
  name: "EditorInfluencia",
  components: {
    ModalEditarHogar,
    ModalEditarTrazados,
  },
  data() {
    return {
      //Filtros
      provincia: '',                  //Provincia a buscar 
      poblacion: '',                  //Población a buscar 
      calle: '',                      //Calle a buscar, inicia la busqueda real
      numero: '',
      ID_DOMICILIO: '',
      CODIGO_OLT: '',
      CODIGO_CTO: '',

      //Filtros trazados
      trazados: {
        CODIGO_OLT: '',
        CODIGO_CTO: '',
        cargandoTrazados: false,
        listaTrazados: '',
        mensajedeErrorTrazados: '',
        tabla: '',
      },

      cambiosHogares: {
        descHogares: '',
        dirty: false,
      },      
      cambiosTrazados: {
        descTrazados: '',
        dirty: false,
      },

      cola_edicion_huella: [],
      cargando_cola_edicion_huella: false,
      tablaCambiosHogares: '',
      cargandoConfirmarCambiosHogares: false,

      colaCambiosTrazados: [],
      tablaCambiosTrazados: '',
      cargandoConfirmarCambiosTrazados: false,

      cargandoDomicilios: false,
      listaHuella: '',

      listaOLT: [],
      poblacionesDistintas: [],
      provincias: [],
      pueblos: [],
      mensajedeError: '',
      tabla: '',
    }
  },
  validations: {
      provincia: {
        maxLength: maxLength(128)
      },
      poblacion: {
        maxLength: maxLength(128)
      },
      calle: {},
      ID_DOMICILIO: {
        maxLength: maxLength(38)
      },      
      CODIGO_OLT: {
        maxLength: maxLength(23)
      },      
      CODIGO_CTO: {
        maxLength: maxLength(14)
      },
      trazados: {
        CODIGO_OLT: {
          maxLength: maxLength(23)
        },      
        CODIGO_CTO: {
          maxLength: maxLength(14)
        }
      },
      cambiosHogares: {
        descHogares: {
          required,
          maxLength: maxLength(255)
        }
      },
      cambiosTrazados: {
        descTrazados: {
          required,
          maxLength: maxLength(255)
        }
      }
    
  },
  methods: {
    ...mapActions(['setColaTrazados']),
    //Información botones interrogación
    infoPage(){
      infoAlert('Buscador de domicilios', 'Puedes buscar cualquier domicilio del área de influencia mediante su <strong>ID DOMICILIO</strong>, <strong>PROVINCIA</strong>, <strong>POBLACION</strong>, <strong>CODIGO_OLT</strong>, <strong>CODIGO_CTO</strong>')
    },
    infoTrazados(){
      infoAlert('Buscador de trazados', 'Aquí puedes buscar los trazados del área de influencia, mediante el <strong>CODIGO_OLT</strong> o <strong>CODIGO_CTO</strong>')
    },
    infoDomicilio(){
      infoAlert('Nombre vía', 'Introduzca el nombre de la vía sin especificar el tipo (Calle, Avenida, Plaza...) <br><br> <small class="fst-italic">Ej.: "San Martín" para "Calle San Martín"</small> <br><br>De este modo podremos buscar el domicilio del cliente.');
    },
    infoCambiosHogares(){
      infoAlert('Cola de cambios de hogares', 'La siguiente tabla es una lista de los <strong>hogares que han sido editados</strong>, haga clic en ejecutar para guardar los cambios definitivamente.');
    },   
    infoCambiosTrazados(){
      infoAlert('Cola de cambios de trazado', 'La siguiente tabla es una lista de los <strong>trazados que han sido editados</strong>, haga clic en ejecutar para guardar los cambios definitivamente.');
    },

    async getTrazados(){
       try {
          this.desseleccionarTrazados()
          this.trazados.cargandoTrazados = true;
          const respuesta = await axios({
              method: 'GET',
              url: '/trazados', 
              params: {
                CODIGO_OLT: this.trazados.CODIGO_OLT,
                CODIGO_CTO: this.trazados.CODIGO_CTO,
              },
              headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.trazados.listaTrazados = [];
          if(respuesta.data.data.trazados && respuesta.data.data.trazados.length){            
            this.trazados.listaTrazados = respuesta.data.data.trazados;
          }
          this.trazados.tabla.clear();
          this.trazados.tabla.rows.add(this.trazados.listaTrazados).draw();
          
          this.trazados.mensajedeErrorTrazados = '';
          this.trazados.cargandoTrazados = false;

        } catch (error) {
           this.trazados.cargandoTrazados = false;
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              errorAlert('¡Error buscando trazados!', error.response.data.msg)
              this.trazados.mensajedeErrorTrazados = error.response.data.msg;
            } else if (error.request) {
              console.log(error.request);
              this.trazados.mensajedeErrorTrazados = 'No se ha recibido respuesta del servidor';
            } else {
              console.log(error.message);
              this.trazados.mensajedeErrorTrazados = error.message || error;
            }
        }
    },
    async getDomicilios(){
        try {
          this.desseleccionarHogares()
          this.cargandoDomicilios = true;          
          const respuesta = await axios({
              method: 'GET',
              url: '/hogares', 
              params: {
                PROVINCIA: this.provincia,                
                POBLACION: this.poblacion,                
                CALLE: this.calle,                
                NUMERO: this.numero,
                ID_DOMICILIO: this.ID_DOMICILIO,
                CODIGO_OLT: this.CODIGO_OLT,
                CODIGO_CTO: this.CODIGO_CTO,
              },
              headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.listaHuella = [];
          if(respuesta.data.data.domicilios && respuesta.data.data.domicilios.length){            
            this.listaHuella = respuesta.data.data.domicilios;
            for (const item of this.listaHuella) {
              item['DIRECCION COMPLETA'] = montarNombreCalle(item);
            }
            this.tabla.clear();
            this.tabla.rows.add(this.listaHuella).draw();
          }
          console.log(respuesta);
          this.mensajedeError = '';
          this.cargandoDomicilios = false;
        } catch (error) {
          console.log("EEEEEEEEEE");
           this.cargandoDomicilios = false;
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              errorAlert('¡Error buscando domicilios!', error.response.data.msg)
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              console.log(error.request);
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              console.log(error.message);
              this.mensajedeError = error.message || error;
            }
        }
    },
    async obtenerPoblaciones(){
       try {
          const respuesta = await axios({
              method: 'GET',
              url: '/hogares/influencia', 
              headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.provincias = respuesta.data.data.influencia
          let listaPueblos = []
          for (const provincia of this.provincias) {
            for (const pueblo of provincia.PUEBLOS) {
              listaPueblos.push({ provincia: provincia.PROVINCIA, pueblo })
            }
          }
          this.pueblos = listaPueblos;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              console.log(error.request);
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              console.log(error.message);
              this.mensajedeError = error.message || error;
            }
        }
    },
    //Formatea una calle legible para el ser humano a partir de los datos de hogares
    montarNombreCalle(domicilio){
      return montarNombreCalle(domicilio);
    },
    cambioPoblacion(event){
      let provincia = $('#listaPueblosInput [value="' + this.poblacion + '"]').data('provincia');
      if(provincia){
        this.provincia = provincia
      }
    },
    //Reinicia la pagina
    resetearFormulario(){            
        this.ID_DOMICILIO = '';
        this.CODIGO_OLT = '';
        this.CODIGO_CTO = '';
        this.provincia = '';
        this.poblacion = '';
        this.calle = '';
        this.numero = '';
        this.listaHuella = [];
        this.mensajedeError = '';
        this.desseleccionarHogares();
        this.$v.$reset();        
    },
    resetearFormularioTrazados(){            
        this.trazados.CODIGO_OLT = '';
        this.trazados.CODIGO_CTO = '';
        this.trazados.listaTrazados = [];
        this.mensajedeErrorTrazados = '';
        this.desseleccionarTrazados();
        this.$v.$reset();        
    },
    initTablaDomicilios(){

        ////////////////////
        //Tabla domicilios//
        //////////////////// 

        this.tabla = $('#tablaDomicilios').DataTable({
              autoWidth: true,
              select: {
                  style:    'multi',
                  selector: 'td:first-child'
              },
              language: { 
                
                "oPaginate": {
                  "sPrevious": "&#10094;&#10094;",
                  "sNext":     "&#10095;&#10095;",
                },
              },
              columns: [
                {
                  data: null,
                  defaultContent: '',
                  className: 'select-checkbox',
                  orderable: false,
                  width: "2rem"
                },
                {
                  data: null,
                  defaultContent: `
                    <i type="button" class="fa-solid fa-pencil fa-2x mx-1 icon-btn color-azul editar-btn" title="Editar"></i>
                    <i type="button" class="fa-solid fa-trash-alt fa-2x mx-1 icon-btn color-rojo borrar-btn" title="Borrar"></i>
                  `,
                  orderable: false,
                },  
                { data: 'DIRECCION COMPLETA' },
                { data: 'ID_DOMICILIO' },
                { data: 'HOGAR_POTENCIAL' },
                { data: 'CODIGO_POSTAL' },
                { data: 'PROVINCIA' },
                { data: 'POBLACION' },
                { data: 'TIPO_VIA' },
                { data: 'NOMBRE_VIA' },
                { data: 'ID_TECNICO' },
                { data: 'NUMERO' },
                { data: 'BIS' },
                { data: 'BLOQUE_FINCA' },
                { data: 'IDENTIFICADOR_FINCA' },
                { data: 'LETRA_FINCA' },
                { data: 'ESCALERA' },
                { data: 'PLANTA' },
                { data: 'MANO_1' },
                { data: 'MANO_2' },
                { data: 'OBSERVACIONES' },
                { data: 'FLAG_DUMMY' },
                { data: 'CODIGO_INE' },
                { data: 'CODIGO_CENSAL' },
                { data: 'CODIGO_PAI' },
                { data: 'CODIGO_OLT',
                  render: (data, type, row) => {
                    return `<div class="d-flex align-items-center"><span>${data}</span><i type="button" class="fa-solid fa-magnifying-glass fa-2x mx-1 icon-btn color-azul BUSCAR-OLT-btn" title="Buscar OLT"></i></div>`
                  } 
                },
                { data: 'CODIGO_CTO',
                  render: (data, type, row) => {
                    return `<div class="d-flex align-items-center"><span>${data}</span><i type="button" class="fa-solid fa-magnifying-glass fa-2x mx-1 icon-btn color-azul BUSCAR-CTO-btn" title="Buscar CTO"></i></div>`
                  } 
                },
                { data: 'TIPO_CTO' },
                { data: 'DIRECCION_CTO' },
                { data: 'TIPO_PERMISO' },
                { data: 'TIPO_CAJA_DERIVACION' },
                { data: 'NUMERO_UI' },
                { data: 'NUMERO_VIVIENDAS' },
                { data: 'FECHA_ALTA' },
                { data: 'CODIGO_CAJA_DERIVACION' },
                { data: 'UBICACION_CAJA_DERIVACION' },
                { data: 'GESCAL_37' },
              ],
              
       });
       //Vincular el boton de editar con su función
       $('#tablaDomicilios tbody').on('click', 'i.editar-btn', (event) => {   
        let rowData = this.tabla.row($(event.target).parents('tr')).data();
        this.editarHogarIndividual(rowData);
       });

      //Vincular el boton de borrar con su función
       $('#tablaDomicilios tbody').on('click', 'i.borrar-btn', async (event) => {   
        let rowData = this.tabla.row($(event.target).parents('tr')).data();
        if(!await borrarHogarEditor([rowData])) return;
        this.obtenerColas()
        this.refrescarTablasColas()
       });

      //Vincular el boton de BUSCAR OLT con su función
       $('#tablaDomicilios tbody').on('click', 'i.BUSCAR-OLT-btn', (event) => {   
        let OLT = this.tabla.row($(event.target).parents('tr')).data().CODIGO_OLT;
        this.buscarOLT(OLT);
       });

      //Vincular el boton de BUSCAR CTO con su función
       $('#tablaDomicilios tbody').on('click', 'i.BUSCAR-CTO-btn', (event) => {   
        let CTO = this.tabla.row($(event.target).parents('tr')).data().CODIGO_CTO;
        this.buscarCTO(CTO);
       });

       $('#tablaDomicilios').on( 'draw.dt', () => {
          //this.mostrarCambiosEnBusquedaHogares();
       });

       //A los 500ms cuando ya se ha creado la tabla se le añade la caracteristica responsive de bootstrap para que se vea mejor
       setTimeout(() => {
         $('#tablaDomicilios').wrap('<div class="table-responsive"></div>')
       }, 500);
    },
    initTablaTrazados(){

        //////////////////
        //Tabla trazados//
        ////////////////// 

        this.trazados.tabla = $('#tablaTrazados').DataTable({
              autoWidth: true,
              select: {
                  style:    'multi',
                  selector: 'td:first-child'
              },
              language: { 
                
                "oPaginate": {
                  "sPrevious": "&#10094;&#10094;",
                  "sNext":     "&#10095;&#10095;",
                },
              },
              columns: [
                {
                  data: null,
                  defaultContent: '',
                  className: 'select-checkbox',
                  orderable: false,
                  width: "2rem"
                },
                {
                  data: null,
                  defaultContent: `
                    <i type="button" class="fa-solid fa-pencil fa-2x mx-1 icon-btn color-azul editar-trazados-btn" title="Editar trazado"></i>
                    <i type="button" class="fa-solid fa-trash-alt fa-2x mx-1 icon-btn color-rojo borrar-trazados-btn" title="Borrar trazado"></i>
                  `,
                  orderable: false
                },  
                { data: 'CODIGO_OLT',
                  render: (data, type, row) => {
                    return `<div class="d-flex align-items-center"><span>${data}</span><i type="button" class="fa-solid fa-magnifying-glass fa-2x mx-1 icon-btn color-azul BUSCARDOM-OLT-btn" title="Buscar domicilios por OLT"></i></div>`
                  } 
                },
                { data: 'MODELO_OLT' },
                { data: 'FRAME_OLT' },
                { data: 'TARJETA_OLT' },
                { data: 'PUERTO_GPON' },
                { data: 'CAPACIDAD_GPON' },
                { data: 'CODIGO_CTO',
                  render: (data, type, row) => {
                    return `<div class="d-flex align-items-center"><span>${data}</span><i type="button" class="fa-solid fa-magnifying-glass fa-2x mx-1 icon-btn color-azul BUSCARDOM-CTO-btn" title="Buscar domicilios por CTO"></i></div>`
                  } 
                },
                { data: 'TIPO_CTO' },
                { data: 'DIRECCION_CTO' },
                { data: 'SPLITTER_ID' },
                { data: 'TIPO_SPLITTER' },
                { data: 'PUERTO_CTO' },
                { data: 'GESTOR_VERTICAL' },
                { data: 'MUTUALIZADO' },
                { 
                  data: 'uid',
                  visible: false 
                },
              ],
              
       });
       $('#tablaTrazados').on( 'draw.dt', () => {
          //this.mostrarCambiosEnBusquedaTrazados();
       } );
       $('#tablaTrazados tbody').on('click', 'i.editar-trazados-btn', (event) => {   
        let rowData = this.trazados.tabla.row($(event.target).parents('tr')).data();
        this.editarTrazadoIndividual(rowData)
       });

       $('#tablaTrazados tbody').on('click', 'i.borrar-trazados-btn', async (event) => {   
        let rowData = this.trazados.tabla.row($(event.target).parents('tr')).data();
        if(!await borrarTrazadoEditor([rowData])) return;
        this.obtenerColas()
        this.refrescarTablasColas()
       });

      //Vincular el boton de BUSCAR DOMICILIO POR OLT con su función
       $('#tablaTrazados tbody').on('click', 'i.BUSCARDOM-OLT-btn', (event) => {   
        let OLT = this.trazados.tabla.row($(event.target).parents('tr')).data().CODIGO_OLT;
        this.buscarDOMOLT(OLT);
       });

      //Vincular el boton de BUSCAR DOMICILIO POR CTO con su función
       $('#tablaTrazados tbody').on('click', 'i.BUSCARDOM-CTO-btn', (event) => {   
        let CTO = this.trazados.tabla.row($(event.target).parents('tr')).data().CODIGO_CTO;
        this.buscarDOMCTO(CTO);
       });

       //A los 500ms cuando ya se ha creado la tabla se le añade la caracteristica responsive de bootstrap para que se vea mejor
       setTimeout(() => {
         $('#tablaTrazados').wrap('<div class="table-responsive"></div>')
       }, 500);
    },
    initTablaCambiosHogares(){
        this.tablaCambiosHogares = $('#cambiosHogaresTabla').DataTable({
              autoWidth: true,
              language: { 
                
                "oPaginate": {
                  "sPrevious": "&#10094;&#10094;",
                  "sNext":     "&#10095;&#10095;",
                },
              },
              columns: [
                {
                  data: null,
                  className: 'dt-body-center',
                  defaultContent: `
                    <i type="button" class="fa-solid fa-xmark fa-2x mx-auto icon-btn text-danger eliminar-cambio-hogar-btn" title="Eliminar cambio"></i>
                  `,
                },  
                { data: 'DIRECCION COMPLETA',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return `
                          <div class="d-flex align-items-center">
                            <i class="fa-solid fs-3 fa-triangle-exclamation text-danger mx-2"> </i>  
                            <div class="d-flex flex-column text-start mx-2">
                              <div v-if="mensajedeError" class="text-danger">
                                Este UUII no se ha encontrado en la base de datos
                              </div>           
                            </div> 
                          </div>
                      `;
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex flex-column text-start">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'ID_DOMICILIO',
                 render: (data, type, rowData) => {
                      return !rowData.ELIMINADO ? data : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data}</strike>
                      </div>
                      `
                    } 
                },
                { data: 'HOGAR_POTENCIAL',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },                
                { data: 'CODIGO_POSTAL',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'PROVINCIA',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'POBLACION',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_VIA',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'NOMBRE_VIA',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'ID_TECNICO',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'NUMERO',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'BIS',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'BLOQUE_FINCA',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'IDENTIFICADOR_FINCA',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'LETRA_FINCA',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'ESCALERA',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'PLANTA',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'MANO_1',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'MANO_2',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'OBSERVACIONES',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'FLAG_DUMMY',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_INE',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_CENSAL',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_PAI',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_OLT',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_CTO',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_CTO',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'DIRECCION_CTO',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_PERMISO',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_CAJA_DERIVACION',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'NUMERO_UI',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'NUMERO_VIVIENDAS',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'FECHA_ALTA',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_CAJA_DERIVACION',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },     
                { data: 'UBICACION_CAJA_DERIVACION',
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },                                            
              ]    
        });
      //A los 500ms cuando ya se ha creado la tabla se le añade la caracteristica responsive de bootstrap para que se vea mejor
      setTimeout(() => {
        $('#cambiosHogaresTabla').wrap('<div class="table-responsive"></div>')
      }, 500);
    
      $('#cambiosHogaresTabla tbody').on('click', 'i.eliminar-cambio-hogar-btn', (event) => {   
        let rowData = this.tablaCambiosHogares.row($(event.target).parents('tr')).data();
        this.eliminarCambioHogar(rowData, event);
      });
      $('#cambiosHogaresTabla').on( 'draw.dt', () => {
          //this.mostrarCambiosEnBusquedaHogares();
      });
      
    },
    initTablaCambiosTrazados(){
        this.tablaCambiosTrazados = $('#cambiosTrazadosTabla').DataTable({
              autoWidth: true,
              language: { 
                
                "oPaginate": {
                  "sPrevious": "&#10094;&#10094;",
                  "sNext":     "&#10095;&#10095;",
                },
              },
              columns: [
                {
                  data: null,
                  className: 'dt-body-center',
                  defaultContent: `
                    <i type="button" class="fa-solid fa-xmark fa-2x mx-auto icon-btn text-danger eliminar-cambio-hogar-btn" title="Eliminar cambio"></i>
                  `,
                },  
                { data: 'CODIGO_OLT',
                  render: (data, type, row) => {
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                  } 
                },
                { data: 'MODELO_OLT', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'FRAME_OLT', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TARJETA_OLT', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'PUERTO_GPON', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CAPACIDAD_GPON', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'CODIGO_CTO', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_CTO', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'DIRECCION_CTO', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'SPLITTER_ID', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'TIPO_SPLITTER', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'PUERTO_CTO', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'GESTOR_VERTICAL', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { data: 'MUTUALIZADO', 
                    render: (data, type, rowData) => {
                      if(!data || rowData.UUII_NO_EXISTE) return '';
                      if(!data.hasOwnProperty('NEW') && !data.hasOwnProperty('OLD')) data = { NEW: data, OLD: ''}
                      return data.OLD == data.NEW ? data.OLD : `
                      <div class="d-flex align-items-center flex-column">
                        <strike class="text-danger">${data.OLD}</strike>
                        <div class="text-success">${data.NEW}</div>
                      </div>
                      `
                    } 
                },
                { 
                  data: 'uid',
                  visible: false 
                },
              ]    
        });
      //A los 500ms cuando ya se ha creado la tabla se le añade la caracteristica responsive de bootstrap para que se vea mejor
        setTimeout(() => {
          $('#cambiosTrazadosTabla').wrap('<div class="table-responsive"></div>')
        }, 500);

        $('#cambiosTrazadosTabla tbody').on('click', 'i.eliminar-cambio-hogar-btn', (event) => {   
          let rowData = this.tablaCambiosTrazados.row($(event.target).parents('tr')).data();
          this.eliminarCambioTrazado(rowData);
         });
        $('#cambiosTrazadosTabla').on( 'draw.dt', () => {
          //this.mostrarCambiosEnBusquedaTrazados();
        });
      
    },
    inicializarDatatable(){    

        this.initTablaDomicilios()
        this.initTablaTrazados()
        this.initTablaCambiosHogares()
        this.initTablaCambiosTrazados()

    },
    scrollToDomicilios(){
      let element = document.getElementById('tablaDomicilios');
      if(element){
        element.scrollIntoView({ behavior: 'smooth'})
      }
    },
    scrollToTrazados(){
      let element = document.getElementById('tablaTrazados');
      if(element){
        element.scrollIntoView({ behavior: 'smooth'})
      }
    },
    async buscarDOMOLT(CODIGO_OLT){
      this.scrollToDomicilios();
      this.resetearFormulario();
      this.CODIGO_OLT = CODIGO_OLT;
      await this.getDomicilios();
    },
    async buscarDOMCTO(CODIGO_CTO){
      this.scrollToDomicilios();
      this.resetearFormulario();
      this.CODIGO_CTO = CODIGO_CTO;
      await this.getDomicilios();
    },  
    async buscarOLT(CODIGO_OLT){
      this.scrollToTrazados();
      this.resetearFormularioTrazados();
      this.trazados.CODIGO_OLT = CODIGO_OLT;
      await this.getTrazados();
    },
    async buscarCTO(CODIGO_CTO){
      this.scrollToTrazados();
      this.resetearFormularioTrazados();
      this.trazados.CODIGO_CTO = CODIGO_CTO;
      await this.getTrazados();
    },

    mostrarResultadoCambios(success = true, msg = '', datos = '', listaErrores = []){
      if(datos){
        //Si tiene información de borrados y editados la ponemos
        let editados = ''
        let borrados = ''
        if(listaErrores.length){

        }
        if(datos.hasOwnProperty('editados')){
          editados = ` 
          <tr>
              <th style="min-width: 9rem;">Editados</th>
              <td class="table-light">
                  ${datos.editados}
              </td>
          </tr>`
        }
        if(datos.hasOwnProperty('borrados')){
          borrados = ` 
          <tr>
              <th style="min-width: 9rem;">Borrados</th>
              <td class="table-light">
                  ${datos.borrados}
              </td>
          </tr>`
        }
        //Mostramos el resumen de la operación
        datos = `
          <br>
          <table class="table table-bordered text-start">
            <tbody>
              <tr>
                  <th style="min-width: 9rem;" class="table-dark">Total</th>
                  <td class="table-dark">
                      ${datos.total}
                  </td>
              </tr> 
              ${editados}
              ${borrados}                                     
            </tbody>
          </table>
          `
      }
      let icon = 'success'
      let title = "Operación completada"
      if(!success){
        icon = 'error';
        title = "¡Error en la operación"
      }
      Swal.fire({
        title,
        html: `
         <p>${msg}</p>
         ${listaErrores.join('<br>')}
         ${datos}
        `,
        icon,
        confirmButtonText: 'Vale'
     })
    },
    async confirmarCambiosTrazados(){
        try {
          this.cambiosTrazados.dirty = true;
          if(!this.cambiosTrazados.descTrazados) return;
          this.cargandoConfirmarCambiosTrazados = true;
          const respuesta = await axios({
              method: 'PUT',
              url: '/trazados', 
              params: {
                DESCRIPCION: this.cambiosTrazados.descTrazados
              },
              data: {
                listaCambios: this.colaTrazados
              },
              headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.mostrarResultadoCambios(true, 'Se ha actualizado los trazados correctamente', respuesta.data.data)
          
          //Borramos la lista de cambios que acabamos de confirmar
          await getTrazados();
          this.borrarListaCambiosTrazados();
          this.cargandoConfirmarCambiosTrazados = false;
        } catch (error) {
           this.cargandoConfirmarCambiosTrazados = false;
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              this.mostrarResultadoCambios(false, error.response.data.msg, error.response.data.data || '', error.response.data.errores || [])
            } else if (error.request) {
              console.log(error.request);
              serverErrorAlert('No se ha recibido respuesta del servidor')
            } else {
              console.log(error.message);
              errorAlert(error.message || error)
            }
        }
    },

    refrescarTablasColas(){
      setTimeout(() => {   
        this.refrescar_cola_huella()
        this.refrescar_cola_trazados();
      }, 500);
    },
    async obtenerColas(){
      //Obtenemos la cola de edición de huella
      await this.obtener_cola_huella();
      this.colaCambiosTrazados = JSON.parse(this.colaTrazados || null)
    },
    obtenerYRefrescarColas(){

      this.cambiosTrazados.dirty = false;
      this.cambiosTrazados.descTrazados = '';
      this.obtenerColas();
      this.refrescarTablasColas();
    },
    refrescar_cola_huella(){      
        this.cambiosHogares.dirty = false;
        this.cambiosHogares.descHogares = '';
        this.tablaCambiosHogares.clear()
        if(this.cola_edicion_huella && this.cola_edicion_huella.length){
          this.tablaCambiosHogares.rows.add(this.cola_edicion_huella).draw();
        }

    },   
    refrescar_cola_trazados(){
        this.cambiosTrazados.dirty = false;
        this.cambiosTrazados.descHogares = '';
        this.tablaCambiosTrazados.clear()
        if(this.colaCambiosTrazados && this.colaCambiosTrazados.length){
          this.tablaCambiosTrazados.rows.add(this.colaCambiosTrazados).draw();
        }
    },       
    async obtener_cola_huella(){
      try {
          this.cargando_cola_edicion_huella = true;
          this.cola_edicion_huella = []
          const respuesta = await axios({
                method: 'GET',
                url: '/hogares/obtener_cola_edicion_huella', 
                headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          
          if(respuesta.data.data.cola_edicion_huella && respuesta.data.data.cola_edicion_huella.length){            
            this.cola_edicion_huella = respuesta.data.data.cola_edicion_huella
          }
          this.cargando_cola_edicion_huella = false;
          this.refrescar_cola_huella()
        } catch (error) {
          this.cargando_cola_edicion_huella = false;
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              errorAlert('¡Error cargando cola de edición de hogares!', error.response.data.msg)
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              console.log(error.request);
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              console.log(error.message);
              this.mensajedeError = error.message || error;
            }
        }
    },
    desseleccionarHogares(){
      $('#selectAllHogares').prop( "checked", false );
      console.log(this.tabla);
      this.tabla.rows().deselect(); 
    },
    seleccionarHogares(evt){
      if ($(evt.target).is( ":checked" )) {
          this.tabla.rows(  ).select();        
      } else {
          this.tabla.rows(  ).deselect(); 
      }
    },
    editarHogarIndividual(domicilio){
      if(!domicilio){
        warningAlert("Ningún domicilio seleccionado", "Debes de seleccionar al menos un domicilio para editar en masa");
        return;
      }
      this.$refs.ModalHogares.cargarHogares([domicilio])
    },
    editarHogaresSeleccionados(){
      let valores = this.tabla.rows( { selected: true } ).data().toArray();
      if(!valores.length){
        warningAlert("Ningún domicilio seleccionado", "Debes de seleccionar al menos un domicilio para editar en masa");
        return;
      }
      this.$refs.ModalHogares.cargarHogares(valores)
    },
    async borrarHogaresSeleccionados(){
      let valores = this.tabla.rows( { selected: true } ).data().toArray()
      if(!valores.length){
        warningAlert("Ningún domicilio seleccionado", "Debes de seleccionar al menos un domicilio para borrar en masa");
        return;
      }
      if(!await borrarHogarEditor(valores)) return;
      this.obtenerYRefrescarColas();
    },      
    async confirmarCambiosHogares(){
        try {
          this.cambiosHogares.dirty = true;
          if(!this.cambiosHogares.descHogares) return;

          this.cargandoConfirmarCambiosHogares = true;
          const respuesta = await axios({
              method: 'PUT',
              url: '/hogares/confirmar_cambios_cola_edicion_huella', 
              params: {
                DESCRIPCION: this.cambiosHogares.descHogares
              },
              data: {
                listaCambios: this.colaHogares
              },
              headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.mostrarResultadoCambios(true, 'Se ha actualizado la huella correctamente', respuesta.data.data)
          await this.getDomicilios();
          await this.obtener_cola_huella()
          this.cargandoConfirmarCambiosHogares = false;
        } catch (error) {
           this.cargandoConfirmarCambiosHogares = false;
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              this.mostrarResultadoCambios(false, error.response.data.msg, error.response.data.data || '', error.response.data.errores || [])
            } else if (error.request) {
              console.log(error.request);
              serverErrorAlert('No se ha recibido respuesta del servidor')
            } else {
              console.log(error.message);
              errorAlert(error.message || error)
            }
        }
    },
    async eliminarCambioHogar(data, event){
      let { ID_DOMICILIO } = data
        try {
          const respuesta = await axios({
                method: 'DELETE',
                url: '/hogares/descartar_uuii_cola_edicion_huella', 
                params: {
                  ID_DOMICILIO
                },
                headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          
          successAlert('¡Operación completada!', respuesta.data.msg)
          this.tablaCambiosHogares.row($(event.target).parents('tr')).remove().draw();
          this.cola_edicion_huella = this.cola_edicion_huella.filter( e => e.ID_DOMICILIO != ID_DOMICILIO )

        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              errorAlert('¡Error eliminando uuii de cola de edición de hogares!', error.response.data.msg)
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              console.log(error.request);
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              console.log(error.message);
              this.mensajedeError = error.message || error;
            }
        }
    },  
    async eliminarTodaColaHogares(){
        try {
          if(!this.cola_edicion_huella || !this.cola_edicion_huella.length) return;
          if(!await confirmAlert('Se eliminará toda la cola de edición.')) return;
          const respuesta = await axios({
                method: 'DELETE',
                url: '/hogares/descartar_cola_edicion_huella', 
                headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          successAlert('¡Operación completada!', respuesta.data.msg)
          this.tablaCambiosHogares.clear().draw();
          this.cola_edicion_huella = []
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              errorAlert('¡Error cargando cola de edición de hogares!', error.response.data.msg)
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              console.log(error.request);
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              console.log(error.message);
              this.mensajedeError = error.message || error;
            }
        }
        //this.mostrarCambiosEnBusquedaHogares()
    },    
    async obtenerOLTs(){
        try {
          const respuesta = await axios({
              method: 'GET',
              url: '/trazados/OLTS', 
              headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.listaOLT = [];
          if(respuesta.data.data.OLTList && respuesta.data.data.OLTList.length){            
            this.listaOLT = respuesta.data.data.OLTList;
          }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              errorAlert('¡Error buscando OLTs!', error.response.data.msg)
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              console.log(error.request);
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              console.log(error.message);
              this.mensajedeError = error.message || error;
            }
        }
    },

    desseleccionarTrazados(){
      $('#selectAllTrazados').prop( "checked", false );
      this.trazados.tabla.rows(  ).deselect(); 
    },
    seleccionarTrazados(evt){
      if ($(evt.target).is( ":checked" )) {
          this.trazados.tabla.rows(  ).select();        
      } else {
          this.trazados.tabla.rows(  ).deselect(); 
      }
    },
    editarTrazadoIndividual(trazado){
      if(!trazado){
        warningAlert("Ningún trazado seleccionado", "Debes de seleccionar al menos un trazado para editar en masa");
        return;
      }
      this.$refs.ModalTrazados.cargarTrazados([trazado])
    },
    editarTrazadosSeleccionados(){
      let valores = this.trazados.tabla.rows( { selected: true } ).data().toArray();
      if(!valores.length){
        warningAlert("Ningún domicilio seleccionado", "Debes de seleccionar al menos un domicilio para editar en masa");
        return;
      }
      this.$refs.ModalTrazados.cargarTrazados(valores)
    },
    async borrarTrazadosSeleccionados(){
      let valores = this.trazados.tabla.rows( { selected: true } ).data().toArray()
      if(!valores.length){
        warningAlert("Ningún domicilio seleccionado", "Debes de seleccionar al menos un domicilio para borrar en masa");
        return;
      }
      if(!await borrarTrazadoEditor(valores)) return;
      this.obtenerYRefrescarColas();
    },
    eliminarCambioTrazado(data){
      let { uid } = data
      let listaCambios = JSON.parse(this.colaTrazados || null);
      if(!listaCambios) listaCambios = []
      listaCambios = listaCambios.filter(e => e.uid != uid)
      this.setColaTrazados(JSON.stringify(listaCambios))
      this.obtenerColas()
      this.tablaCambiosTrazados.row($(event.target).parents('tr')).remove().draw();
    },
    borrarListaCambiosTrazados(){
        this.setColaTrazados('')
        this.obtenerYRefrescarColas();
        //this.mostrarCambiosEnBusquedaTrazados()
    },    
      
    loseFocus(event){    
      event.target.blur()
    },

  },
  created() {
    this.obtenerOLTs();
    this.obtenerPoblaciones();
    this.obtenerColas();
  },
  computed: {
    ...mapState(['colaTrazados'])
  },
  mounted(){
    this.inicializarDatatable();
    document.getElementsByClassName("Panel")[0].scrollTop = 0;
    this.refrescar_cola_trazados();


  }
};
</script>


<style scoped>
  .number-input {
    width: 10rem;
  }
  .datatables_info{
    font-size: smaller;
  }
.input-desc{
  width: 100%;
  max-width: 60rem;
}

</style>
