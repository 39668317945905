<template>

    <div class="card page-card border-0 shadow-lg my-5">
      <div class="d-flex justify-content-between mx-4 my-4 align-items-center">
        <!-- Header -->
        <div class="d-flex align-items-baseline justify-content-start">
          <h1>Exportador de AI</h1>
          <i class="fa-solid fa-2x fa-circle-question ms-2 clickable" @click="infoPage()"></i>
        </div>
      </div>
      <div v-if="mensajedeError" class="alert alert-warning d-flex align-items-center mx-2" role="alert">
        <i class="fa-solid fs-3 fa-triangle-exclamation text-warning mx-2"> </i>   
        <div class="d-flex flex-column text-start">
          <div v-if="mensajedeError" class="text-danger">
            {{mensajedeError}}
          </div>  
        </div>
      </div>
      <!-- ############### -->
      <!-- Huella Exporter -->
      <!-- ############### -->
      <div class="m-3 card shadow">
          <div class="card-body"> 
            <!-- header -->
            <div class="d-flex align-items-baseline justify-content-start">
                <h4>Exportador de hogares</h4>
                <i class="fa-solid fa-circle-question clickable ms-2 fs-5" @click="infoHuellaExporter()"></i>
            </div>

            <!-- Body -->
            <div class="row">

              <!-- Provincia -->
              <div class="col-sm-12 col-xxl-6 my-1">
                <div class="row d-flex align-items-center">
                  <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                    <label for="Provincia" class="form-control-label mx-1  d-flex align-items-center">Provincia</label>
                  </div>
                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                    <div class="row d-flex flex-row">
                      <div class="col-10">  
                        <input id="Provincia" class="form-control w-100 m-0" list="listaProvincias" :disabled="cargandoPeticion" maxlength="65" v-model.lazy="$v.provincia.$model" placeholder="Provincia" :class="{ 'is-invalid': $v.provincia.$error}" autocomplete="off" @change="loseFocus;">
                        <datalist id="listaProvincias">
                          <option v-for="(item, index) in provincias" :key="index" :value="item.PROVINCIA"></option> 
                        </datalist>
                      </div>
                    </div>                      
                  </div>
                </div>
                <div class="row justify-content-end text-start">
                  <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                    <small v-show="$v.provincia.$dirty && !$v.provincia.maxLength" class="text-danger">
                      No puede introducir tantos caracteres
                    </small>                                
                  </div>
              </div>                                  
              </div>

              <!-- Pueblo -->
              <div class="col-sm-12 col-xxl-6 my-1">
                    <div class="row d-flex align-items-center">
                      <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                        <label for="poblacion" class="form-control-label mx-1  d-flex align-items-center">Población</label>
                      </div>
                      <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9">
                        <div class="row d-flex flex-row">
                          <div class="col-10">  
                            <input id="poblacion" class="form-control w-100 m-0" list="listaPueblosInput" :disabled="cargandoPeticion" maxlength="65" v-model.lazy="poblacion" placeholder="Población" :class="{ 'is-invalid': $v.poblacion.$error}" autocomplete="off" @change="loseFocus; cambioPoblacion($event)">
                            <datalist id="listaPueblosInput">
                              <option v-for="(item, index) in pueblos" :key="index" :data-provincia="item.provincia" :value="item.pueblo">{{item.provincia}}</option> 
                            </datalist>     
                          </div>
                        </div>                            
                      </div>
                    </div>
                    <div class="row justify-content-end text-start">
                      <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                        <small v-show="$v.poblacion.$dirty && !$v.poblacion.maxLength" class="text-danger">
                          No puede introducir tantos caracteres
                        </small>                                
                      </div>
                  </div>    
              </div>    

              <!-- CODIGO_OLT -->
              <div class="col-sm-12 col-xxl-6 my-1">
                    <div class="row d-flex align-items-center">
                      <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                        <label for="CODIGO_OLT" class="form-control-label mx-1  d-flex align-items-center">Codigo OLT</label>
                      </div>
                      <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                        <div class="row d-flex flex-row">
                          <div class="col-10">
                            <input id="CODIGO_OLT" class="form-control w-100 m-0" list="listaOLTHogares" :disabled="cargandoPeticion"  maxlength="23" v-model.lazy="$v.CODIGO_OLT.$model" placeholder="CODIGO OLT" :class="{ 'is-invalid': $v.CODIGO_OLT.$error}" autocomplete="off" @change="loseFocus">
                            <datalist id="listaOLTHogares">
                              <option v-for="(item, index) in listaOLT" :key="index" :value="item.CODIGO_OLT">{{item.POBLACION}}</option> 
                            </datalist> 
                          </div>
                        </div>
                      </div>
                    </div>
                  <div class="row justify-content-end text-start">
                    <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                      <small v-show="$v.CODIGO_OLT.$dirty && !$v.CODIGO_OLT.maxLength" class="text-danger">
                        No puede introducir tantos caracteres
                      </small>                                
                    </div>
                  </div>
              </div>

              <!-- CODIGO_CTO -->
              <div class="col-sm-12 col-xxl-6 my-1">
                    <div class="row d-flex align-items-center">
                      <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                        <label for="CODIGO_CTO" class="form-control-label mx-1  d-flex align-items-center">Codigo CTO</label>
                      </div>
                      <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                        <div class="row d-flex flex-row">
                          <div class="col-10">
                            <input id="CODIGO_CTO" class="form-control w-100 m-0" type="text" :disabled="cargandoPeticion" maxlength="14" v-model="$v.CODIGO_CTO.$model" placeholder="CODIGO CTO" :class="{ 'is-invalid': $v.CODIGO_CTO.$error}" autocomplete="off">
                          </div>
                        </div>
                      </div>
                    </div>
                  <div class="row justify-content-end text-start">
                    <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                      <small v-show="$v.CODIGO_CTO.$dirty && !$v.CODIGO_CTO.maxLength" class="text-danger">
                        No puede introducir tantos caracteres
                      </small>                                
                    </div>
                  </div>
              </div>

              <!-- ID_TECNICO -->
              <div class="col-sm-12 col-xxl-6 my-1">
                      <div class="row d-flex align-items-center">
                        <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                          <label for="ID_TECNICO" class="form-control-label mx-1  d-flex align-items-center">Id técnico</label>
                        </div>
                        <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                          <div class="row d-flex flex-row">
                            <div class="col-10">
                              <input id="ID_TECNICO"  class="form-control w-100 m-0" type="text" :disabled="cargandoPeticion" maxlength="12" v-model="$v.ID_TECNICO.$model" placeholder="ID_TECNICO" :class="{ 'is-invalid': $v.ID_TECNICO.$error}" autocomplete="off">
                            </div>
                          </div>
                        </div>
                      </div>
                    <div class="row justify-content-end text-start">
                      <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                        <small v-show="$v.ID_TECNICO.$dirty && !$v.ID_TECNICO.maxLength" class="text-danger">
                          No puede introducir tantos caracteres
                        </small>                                
                      </div>
                    </div>
                </div>              

            <!-- ID_DOMICILIO -->
            <div class="col-sm-12 col-xxl-6 my-1">
                    <div class="row d-flex align-items-center">
                      <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                        <label for="ID_DOMICILIO_FILTRO" class="form-control-label mx-1  d-flex align-items-center">Id domicilio</label>
                      </div>
                      <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 ">
                        <div class="row d-flex flex-row">
                          <div class="col-10">
                            <input id="ID_DOMICILIO_FILTRO"  class="form-control w-100 m-0" type="text" :disabled="cargandoPeticion" maxlength="38" v-model="$v.ID_DOMICILIO.$model" placeholder="ID DOMICILIO" :class="{ 'is-invalid': $v.ID_DOMICILIO.$error}" autofocus autocomplete="off">
                          </div>
                        </div>
                      </div>
                    </div>
                  <div class="row justify-content-end text-start">
                    <div class="col-8 col-sm-8 col-md-9 col-lg-10 col-xxl-9 d-flex flex-column">
                      <small v-show="$v.ID_DOMICILIO.$dirty && !$v.ID_DOMICILIO.maxLength" class="text-danger">
                        No puede introducir tantos caracteres
                      </small>                                
                    </div>
                  </div>
            </div>

            <!-- Numero -->
            <div class="col-sm-12 col-xxl-6 my-1">
              <div class="row d-flex align-items-center">
                    <div class="col-4 col-sm-4 col-md-3 col-lg-2 col-xxl-3 d-flex align-items-center justify-content-end justify-content-md-end">
                      <label for="numero" class="form-control-label mx-1  d-flex align-items-center">Número</label>
                    </div>
                    <div class="number-input">
                      <input id="numero" class="form-control w-100 m-0" type="text" :disabled="cargandoPeticion" min="0"  maxlength="65" placeholder="Número" v-model="numero" autocomplete="off">                                                   
                    </div>
              </div>
            </div>   
            </div>              

            <div class="col-sm-12 mb-2 mt-3">
              <!-- Botón para colapsar/expandir -->
              <button class="btn btn-primary text-white fw-bold fit-content mx-2 mb-2 columnas action-width" v-b-toggle.columnas @click="camposSeleccionados=[]">
              </button>
              <!-- Collapse Columnas -->
              <b-collapse id="columnas" v-model="columnasVisible">
                <hr class="mt-2">
                <div class="m-2">
                  <b-button class="btn btn-sm btn-secondary text-white fw-bold fit-content mx-2 mb-2 columnas action-width" @click="camposSeleccionados=[...camposDisponibles]">
                    <i class="fa-solid fa-check fa-1x mx-1"></i>
                    Seleccionar todo
                  </b-button>
                  <b-button class="btn btn-sm btn-secondary text-white fw-bold fit-content mx-2 mb-2 columnas action-width" @click="camposSeleccionados=[]">
                    <i class="fa-solid fa-xmark fa-1x mx-1"></i>
                    Quitar todo
                  </b-button>     
                  <hr>           
                </div>
                <div class="overflow-scoll">
                  <button class="btn btn-secondary btn-sm m-1 no-select" :class="{ 'btn-success': camposSeleccionados.includes(campo) }"
                      v-for="(campo, index) in camposDisponibles"
                      :key="index"
                      @click.prevent="toggleCampo(campo)"
                      style="font-size: 0.5;"
                      >
  
                    <input class="form-check-input d-none" type="checkbox" :id="campo" v-model="camposSeleccionados" :value="campo">
                    <label class="form-check-label clickable" :for="campo">
                      {{ campo }}
                    </label>
                  </button>
                </div>
              </b-collapse>
            </div>

            <hr class="mt-4">
            <div class="d-flex justify-content-center my-4">
              <button type="button" class="btn btn-secondary mx-2" @click="resetearFormulario()" title="Limpiar filtros">
                <i class="fa-solid fa-eraser fa-1x mx-1"></i>
                Limpiar
              </button>              

              <button type="button" :disabled="cargandoPeticion" class="btn btn-success mx-2" title="Descargar csv" @click="exportFilteredHogares()">
                <i v-if="!cargandoPeticion" class="fa-solid fa-file-csv fa-1x mx-1" title="Exportar hogares en CSV"></i>
                <div v-else class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>                
                Descargar CSV
              </button>           

            </div>
          </div>
      </div>

    </div>
</template>

<script>
import { errorAlert, infoAlert, noResponseAlert, serverErrorAlert, successAlert } from '../../helpers/fastAlert';
import { maxLength, required } from 'vuelidate/lib/validators';
import $ from 'jquery';
import axios from "axios";

export default {
  name: 'AIExporter',
  data() {
    return {
      mensajedeError: '',
      cargandoPeticion: false,
      
      //Filtros
      provincia: '',                 
      poblacion: '',          
      CODIGO_OLT: '',
      CODIGO_CTO: '',
      ID_TECNICO: '',
      ID_DOMICILIO: '',
      numero: '',
      camposSeleccionados: [],

      listaOLT: [],
      poblacionesDistintas: [],
      provincias: [],
      pueblos: [],
      camposDisponibles: [
          "ID_DOMICILIO", "HOGAR_POTENCIAL", "CODIGO_POSTAL", "PROVINCIA", "POBLACION", "TIPO_VIA", "NOMBRE_VIA",
          "ID_TECNICO", "NUMERO", "BIS", "BLOQUE_FINCA", "IDENTIFICADOR_FINCA", "LETRA_FINCA", "ESCALERA", "PLANTA",
          "MANO_1", "MANO_2", "OBSERVACIONES", "FLAG_DUMMY", "CODIGO_INE", "CODIGO_CENSAL", "CODIGO_PAI", "CODIGO_OLT",
          "CODIGO_CTO", "TIPO_CTO", "DIRECCION_CTO", "TIPO_PERMISO", "TIPO_CAJA_DERIVACION", "NUMERO_UI", 
          "NUMERO_VIVIENDAS", "FECHA_ALTA", "CODIGO_CAJA_DERIVACION", "UBICACION_CAJA_DERIVACION", "GESCAL_37", 
          "COD_POLIGONO", "REF_CATASTRAL", "LATITUD", "LONGITUD"
      ],
      columnasVisible: false, // Estado inicial del colapsable

    } 
  },
  validations: {
      provincia: {
        maxLength: maxLength(128)
      },
      poblacion: {
        maxLength: maxLength(128)
      },
      CODIGO_OLT: {
        maxLength: maxLength(23)
      },      
      CODIGO_CTO: {
        maxLength: maxLength(14)
      },
      ID_TECNICO: {
        maxLength: maxLength(14)
      },      
      ID_DOMICILIO: {
        maxLength: maxLength(38)
      },      
  },  
  methods: {
    //Botones informativos
    infoPage(){
      infoAlert('Exportador de AI', 'Desde el exportador de AI podrás obtener un reporte del AI Asteo.')
    },
    infoHuellaExporter(){
      infoAlert('Exportador de huella', 'Genera un CSV con los hogares actuales en la base de datos aplicando los filtros deseados.')
    },    
    toggleCampo(campo) {
      const index = this.camposSeleccionados.indexOf(campo);
      // Añadir o quitar el campo de la lista seleccionada
      if (index > -1) {
        this.camposSeleccionados.splice(index, 1);
      } else {
        this.camposSeleccionados.push(campo);
      }
    },    
    convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      const headers = Object.keys(array[0]).join(';');
      const rows = array.map(obj => Object.values(obj).join(';'));
      return [headers, ...rows].join('\n');
    },
    async exportFilteredHogares(){
      try {        
          this.cargandoPeticion = true;
          const respuesta = await axios({
            method: 'GET',
            url: '/hogares',
            params: {
                PROVINCIA: this.provincia,                
                POBLACION: this.poblacion,                
                CODIGO_OLT: this.CODIGO_OLT,
                CODIGO_CTO: this.CODIGO_CTO,
                ID_TECNICO: this.ID_TECNICO,
                ID_DOMICILIO: this.ID_DOMICILIO,
                NUMERO: this.numero,
                camposSeleccionados: this.camposSeleccionados
              },            
            headers: { 
              'Content-Type': 'multipart/form-data', 
              'x-token': localStorage.getItem('x-token') 
            }
          });
          let { domicilios: hogares_list } = respuesta.data.data
          if (!hogares_list || !hogares_list.length){
            infoAlert("¡Ningún registro encontrado!", "Ningún registro ha cumplido el criterio");
            return;
          }          
          try {            
            this.downloadCSV(hogares_list);
            successAlert("¡Operación completada!", "Se ha obtenido la huella");
            this.cargandoPeticion = false;
          } catch (error) {
            this.cargandoPeticion = false;
            errorAlert("Error generando fichero", "Se ha recibido los datos pero no se pudo generar el fichero de salida. " + error)
          }

        } catch (error) {
          this.cargandoPeticion = false;
          if (error.response && error.response.data && error.response.data.msg) {
            // Mostrar solo la alerta de error si no hay CSV
            console.log("error controlado", error.response);
            errorAlert('Error importando', error.response.data.msg || '');
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            noResponseAlert();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message || error);
            serverErrorAlert(error.message || error);
          }
        }
    }, 
    async obtenerOLTs(){
        try {
          const respuesta = await axios({
              method: 'GET',
              url: '/trazados/OLTS', 
              headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.listaOLT = [];
          if(respuesta.data.data.OLTList && respuesta.data.data.OLTList.length){            
            this.listaOLT = respuesta.data.data.OLTList;
          }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              errorAlert('¡Error buscando OLTs!', error.response.data.msg)
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              console.log(error.request);
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              console.log(error.message);
              this.mensajedeError = error.message || error;
            }
        }
    },      
    async obtenerPoblaciones(){
       try {
          const respuesta = await axios({
              method: 'GET',
              url: '/hogares/influencia', 
              headers : { 'Content-Type': 'application/json', 'x-token': localStorage.getItem('x-token') }
          });
          this.provincias = respuesta.data.data.influencia
          let listaPueblos = []
          for (const provincia of this.provincias) {
            for (const pueblo of provincia.PUEBLOS) {
              listaPueblos.push({ provincia: provincia.PROVINCIA, pueblo })
            }
          }
          this.pueblos = listaPueblos;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.msg) {
              console.log(error.response);
              this.mensajedeError = error.response.data.msg;
            } else if (error.request) {
              console.log(error.request);
              this.mensajedeError = 'No se ha recibido respuesta del servidor';
            } else {
              console.log(error.message);
              this.mensajedeError = error.message || error;
            }
        }
    },
    cambioPoblacion(event){
      let provincia = $('#listaPueblosInput [value="' + this.poblacion + '"]').data('provincia');
      if(provincia){
        this.provincia = provincia
      }
    },
    //Reinicia la pagina
    resetearFormulario(){            
      this.provincia = '';
      this.poblacion = '';
      this.CODIGO_OLT = '';
      this.CODIGO_CTO = '';
      this.ID_TECNICO = '';
      this.ID_DOMICILIO = '';
      this.numero = '';
      this.mensajedeError = '';
      this.camposSeleccionados = []
      this.$v.$reset();   
      this.columnasVisible = false;

    },       
    loseFocus(event){    
      event.target.blur()
    },     
    downloadCSV(list) {
      const  processedCsvContent = this.convertToCSV(list);
      const processedBlob = new Blob([processedCsvContent], { type: 'text/csv;charset=utf-8;' });
      const processedLink = document.createElement('a');
      processedLink.href = URL.createObjectURL(processedBlob);
      const now = new Date();
      const formattedDate = `${now.getFullYear()}_${String(now.getMonth() + 1).padStart(2, '0')}_${String(now.getDate()).padStart(2, '0')}__${String(now.getHours()).padStart(2, '0')}_${String(now.getMinutes()).padStart(2, '0')}`;
      processedLink.setAttribute('download', `hogares_export_${formattedDate}.csv`);
      document.body.appendChild(processedLink);
      processedLink.click();
      document.body.removeChild(processedLink);      
    }
  },
  created() {
    this.obtenerOLTs();
    this.obtenerPoblaciones();    
  },
  mounted(){
  },
}
</script>

<style scoped>
.number-input {
    width: 10rem;
}

.input-desc{
  width: 100%;
  max-width: 60rem;
}

button[aria-expanded='true'].columnas:before{
  content: "No seleccionar columnas";
}

button[aria-expanded='false'].columnas:before{
  content: "Seleccionar columnas";
}

</style>
